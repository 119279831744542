import React from 'react'; 
import { useState, useEffect } from 'react';
import { handleUserLikedStory, getUserByUsername, deleteStory } from '../../services/firebase';
import Comments from './Comments';
import formatDistance from 'date-fns/formatDistance';
import { Link } from 'react-router-dom';

function Story({ username, text, likes, comments, likedStory, docId, userId, timeStamp, isAdminHere, creatorMadeStory }) {
  const [ toggleLiked, setToggleLiked ] = useState(likedStory)
  const [ storyLikes, setStoryLikes ] = useState(likes)
  const [ toggleComment, setToggleComment ] = useState(false)
  const [ currentImageLink, setCurrentImageLink ] = useState("")
  const [ storyWasDeleted, setStoryWasDeleted ] = useState(false)

  const handleLikedStory = async () => {
    setToggleLiked((toggleLiked) => !toggleLiked)
    await handleUserLikedStory(docId, userId, toggleLiked)

    setStoryLikes((storyLikes) => (toggleLiked ? storyLikes - 1 : storyLikes + 1))
  }

  const handleNewComment = () => {
    setToggleComment((toggleComment) => !toggleComment)
  }

  const setImageLink = async () => {
    const user = await getUserByUsername(username)
    user.map((user) => {
      setCurrentImageLink(user.imageLink)
    })
  }

  const deleteAStory = async () => {
    await deleteStory(docId)
    setStoryWasDeleted(true)
  }

  useEffect(() => {
    setImageLink()
  }, [username])

  return (
    <div className='w-5/6 border border-gray shadow-md rounded-md flex-col justify-center my-2'>
      <div className='w-full h-12 flex justify-between items-center border-b border-gray px-2'> 
        <div className='flex items-center'>
          <Link to={`/p/${username}`} className="flex items-center">
            {
              currentImageLink === "" ? 
              <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-8 text-green border-2 border-green rounded-md mr-2" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor" 
              strokeWidth={2}>
              <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg> : <img src={currentImageLink} className='h-8 w-8 rounded-md mr-2' alt="Profile"></img>
            }
            <p className='text-sm'>{username}</p>
          </Link>
        </div>
        <div>
        <p className='md:text-sm text-xs text-right text-gray-base'>
          {formatDistance(Number(timeStamp), new Date())} ago
        </p>
        </div>
      </div>
      { storyWasDeleted !== true ?
      <div>
        <p className='m-2 text-sm'>{text}</p>
      </div>
      : 
      <div className='w-full h-12 flex justify-center items-center'>
        <p className='text-green text-lg font-bold '>Story Deleted</p>
      </div>}
      <div className='ml-2 mb-2 mr-2 flex items-center w-full justify-start'>
        <div className='w-20 flex items-center justify-start'>
          <button onClick={handleLikedStory} className={`flex items-center ${ storyWasDeleted === true ? 'opacity-25' : null}`}>
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className={`h-6 mr-1 cursor-pointer ${
              toggleLiked && storyWasDeleted !== true ? "fill-light-red text-light-red" : "text-gray-base"
            }`}
            fill="none"
            viewBox="0 0 24 24" 
            stroke="currentColor" 
            strokeWidth={2}>
            <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
            </svg>
            <p className={`text-sm mr-2 ${ toggleLiked && storyWasDeleted !== true ? "text-light-red" : "text-gray-base"}`}>
              {storyLikes}
            </p>
          </button>
        </div>
        <div className='w-20 flex items-center'>
          <button onClick={handleNewComment} disabled={storyWasDeleted === true} className={` flex items-center ${storyWasDeleted === true ? 'opacity-25' : null }`}>
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className={`h-6 cursor-pointer ${ toggleComment === true ? "text-green"
          : "text-gray-base"}`} 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor" 
            strokeWidth={2}>
            <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
            </svg>
            <p className={`text-sm ml-1 ${ toggleComment === true ? "text-green" : "text-gray-base"}`}>
              {comments.length}
            </p>
          </button>
        </div>
        {
          isAdminHere === true || creatorMadeStory === true ? 
          <button onClick={deleteAStory} disabled={storyWasDeleted === true} className={`hover:cursor-pointer ${storyWasDeleted === true ? 'opacity-25' : null }`}>
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 ml-2 text-gray-base" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor" 
            strokeWidth={2}>
            <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </button> : null
        }
      </div>
      <div>
        <Comments 
        comments={comments} 
        docId={docId} 
        displayName={username} 
        toggleComment={toggleComment} />
      </div>
    </div>
  )
}

export default Story