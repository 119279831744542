import React from 'react'; 
import { useContext, useEffect, useState } from 'react';
import UserContext from '../contexts/Authcontext';
import useUser from '../hooks/use-user';
import { getStories, checkIfUserIsAdmin } from '../services/firebase';
import Story from './story/Story'; 
import Skeleton from 'react-loading-skeleton';

function Timeline() {
    const data = useContext(UserContext)
    const { userInfo } = useUser(data.uid)
    const [storyData, setStoryData] = useState(false)
    const [storyCounter, setStoryCounter] = useState(10)
    const [adminPresent, setAdminPresent] = useState(false)
    const [loading, setLoading] = useState(true)

    const addToStoryCounter = () => {
        setStoryCounter(storyCounter + 10)
    }

    useEffect(() => {

        async function getTimelineStories() {
            const followingLength = userInfo.userData.following.length
            const dividedFollowingLength = followingLength / 10 
            const data = await checkIfUserIsAdmin(userInfo.userData.userId)
            setLoading(false)
            for(let i = 0; i < dividedFollowingLength; i++) {
                const stories = await getStories(userInfo.userData.following, userInfo.userData.userId)
                setStoryData(stories)
                console.log(stories)
            }

            if(data === true) {
                setAdminPresent(true)
            }
        }

        if (userInfo !== undefined) {
            getTimelineStories()
        }  
    }, [userInfo])

    return (
        <div className='w-full'>
            { userInfo || loading !== true ? 
            <div className='w-full flex flex-col md:items-end items-center'>
                { storyData === false ? null : storyData.slice(0, storyCounter).map((story) => {
                    return (
                        <Story 
                        username={story.username} 
                        text={story.story}
                        likes={story.likes.length}
                        likedStory={story.userLikedStory}
                        comments={story.comments}
                        docId={story.docId}
                        userId={data.uid}
                        timeStamp={story.dateCreated}
                        isAdminHere={adminPresent}
                        />
                    )
                })}
                { storyData === false ? null : storyData.length >= 10 && storyCounter < storyData.length && (
                    <div className='w-5/6 flex justify-center my-2'>
                        <button onClick={addToStoryCounter} className="shadow-md border-2 py-1.5 px-2 border-green rounded-full flex items-center justify-center">
                            <p className='font-bold text-green'>See More Stories</p>
                        </button>
                    </div>
                )}
                { storyData === false ?
                <div className='w-5/6 mt-24 flex items-center justify-center'>
                    <p className='font-bold text-green text-lg'>Add more people to see their stories</p>
                </div> 
                : null}
            </div>
            : 
            <div className='flex flex-col w-full items-end'>
                <Skeleton count={1} width={750} height={300}>
                </Skeleton>
                <Skeleton className="mt-3" count={1} width={750} height={300}>
                </Skeleton>
            </div>
            }
        </div>
    )
}

export default Timeline