import React from 'react'; 
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { getUserByUsername } from '../services/firebase';
import * as ROUTES from '../constants/routes'; 
import ProfileBody from '../components/profile/ProfileBody';
import Header from '../components/Header';
import ProfileInfo from '../components/profile/ProfileInfo';
import Skeleton from 'react-loading-skeleton';
import UserContext from '../contexts/Authcontext';

function Profile() {
  const usernameObj = useParams()
  const [ userData, setUserData ] = useState(null)
  const [ isThisUserProfile, setIsThisUserProfile] = useState(false)

  const navigate = useNavigate()
  const currentUser = useContext(UserContext)

  useEffect(() => { 
    async function doesUserExsist() {
      const user = await getUserByUsername(usernameObj.username)
      console.log(userData)

      if( user !== [] ) {
        setUserData(user)
        let username = ''
        user.map((user) => {
          username = user.username
        })
        if(username === currentUser.displayName) {
          setIsThisUserProfile(true)
        }
      } else {
        navigate(ROUTES.LOGIN)
      }
    }

    doesUserExsist()
  }, [usernameObj, navigate])

  return (
    <div>
      <Header />
      { userData !== null ? 
      userData.map((data) => {
        return (
          <div className='touch-pan-auto'>
            <ProfileInfo 
            userId={data.userId}
            docId={data.id}
            followersLength={data.followers.length}
            followingLength={data.following.length}
            username={data.username}
            isUserProfile={isThisUserProfile}
            currentImageLink={data.imageLink}
            type={data.type}
            />
            <ProfileBody 
            username={data.username}
            userId={data.userId}
            userMadeStory={isThisUserProfile}/>
          </div>
        )
      }) : 
     <div className='w-full flex flex-col items-center'>
      <Skeleton height={150} width={500} className="my-16"></Skeleton>
      <Skeleton height={200} width={800}></Skeleton>
     </div>
     }
    </div>
  )
}

export default Profile