import { useState } from 'react'; 
import { Link } from 'react-router-dom';
import AddComment from './Add-Comment';
import useUser from '../../hooks/use-user';
import { useContext } from 'react';
import UserContext from '../../contexts/Authcontext';

function Comments({ comments, docId, toggleComment}) {
    const [ totalComments, setTotalComments ] = useState(comments)
    const [ commentCounter, setCommentCounter ] = useState(3)
    const data = useContext(UserContext)
    const { userInfo } = useUser(data.uid)

    const showMoreComments = () => {
        setCommentCounter(commentCounter + 3)
    }

    return (
        <div>
            {totalComments.slice(0, commentCounter).map((data) => {
                return (
                    <p className="ml-2 text-sm mb-2" key={`${data.text}-${data.displayName}`}>
                        <Link to={`/p/${data.displayName}`}>
                            <span className='font-bold'>{data.displayName}</span>
                        </Link>
                        <span className='text-gray-base text-sm ml-2'>{data.text}</span>
                    </p>
                )
            })}
            {totalComments.length >= 3 && commentCounter < totalComments.length && (
                <button
                type='button' 
                className='flex'
                onClick={showMoreComments}>
                    <p className='text-gray-base text-sm mb-2 ml-2'>
                        See More Comments
                    </p>
                    <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-5 text-gray-base ml-1" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor" 
                    strokeWidth={2}>
                    <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z" />
                    </svg>
                </button> 
            )}
            {
              toggleComment === true && userInfo !== undefined ? 
              <div className='w-full h-12 border-t border-b border-gray'>
                <AddComment 
                docId={docId} 
                displayName={userInfo.userData.username} 
                totalComments={totalComments} 
                setTotalComments={setTotalComments}/>
              </div> : 
              null 
            }
        </div>
    )
}

export default Comments