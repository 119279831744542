import React from 'react'; 
import { updateUserFollowed, updateUserFollowing, getUserByUsername } from '../services/firebase';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function SuggestedProfile({ username, profileId, loggedInUserDocId, profileDocId, currentUserId}) {

    const [followed, setFollowed] = useState(false)
    const [currentImageLink, setCurrentImageLink] = useState("")

    const handleFollowUser = async function () {
        setFollowed(true)
        await updateUserFollowing(loggedInUserDocId, profileId, false)
        await updateUserFollowed(profileDocId, currentUserId, false)
    }

    const setImageLink = async () => {
        const user = await getUserByUsername(username)
        user.map((user) => {
          setCurrentImageLink(user.imageLink)
        })
    }

    useEffect(() => {
        setImageLink()
    }, [username])

    return !followed ? (
        <div className='md:w-full w-1/4 h-12 flex md:flex-row flex-col items-center justify-between md:mt-2 mt-7 md:mx-0 mx-4'>
            <Link className="" to={`/p/${username}`}>
                <div className='flex md:flex-row flex-col justify-center items-center'>
                    {
                    currentImageLink === "" ? 
                    <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="md:h-7 h-10 text-green border-2 border-green rounded-md md:mr-2 mr-0 md:mx-2 mx-0" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor" 
                    strokeWidth={2}>
                    <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    : <img src={currentImageLink} className='md:h-8 md:w-8 h-10 w-10 md:rounded-full rounded-md md:mr-2 md:mx-2 mx-0' alt="Profile"></img>
                    }
                    <p className='md:ml-1 ml-0 md:text-sm text-xs md:mt-0 mt-1'>{ username }</p>
                </div>
            </Link>
            { !followed ? 
                <button onClick={handleFollowUser} className="md:block hidden">
                    <p className='text-white text-xs font-bold flex items-center ml-1 justify-center py-1 px-1.5 bg-green rounded-full'>Follow</p>
                </button>
                : 
                <p className='text-sm text-green font-bold'>Following</p>
            }
        </div>
    ) : null
}

export default SuggestedProfile