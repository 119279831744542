import React from 'react';
import { addComment } from '../../services/firebase';
import { useState } from 'react';

function AddComment({ docId, displayName, totalComments, setTotalComments }) {

    const [ commentInput, setCommentInput ] = useState('')

    const handleNewComment = async () => {
        setTotalComments([...totalComments, {text: commentInput, displayName}])
        await addComment(docId, commentInput, displayName)
        setCommentInput('')
    }

    return (
        <form 
        method='POST'
        onSubmit={(event) => commentInput.length >= 1 ? handleNewComment : event.preventDefault()}
        className='flex items-center'>
            <input 
            aria-label='Add a comment'
            autoComplete='off'
            className='outline-none text-sm text-gray-base w-full h-full py-3 pl-2 mt-1 mb-2'
            placeholder='What are you thinking?'
            onChange={({target}) => setCommentInput(target.value)}
            value={commentInput}
            type="text"
            />
            <button 
            className={`text-sm text-white mb-1 mr-2 h-7 w-12 bg-green shadow-md rounded-full ${commentInput === '' ? "opacity-25" 
            : null }`}
            onClick={handleNewComment} 
            type="button"
            disabled={commentInput.length < 1}>Post</button>
        </form>
    )
}

export default AddComment