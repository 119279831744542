import React from 'react';
import { Link } from 'react-router-dom'; 
import * as ROUTES from '../constants/routes'; 
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useState } from 'react';

function Header() {
  const auth = getAuth()
  const navigate = useNavigate()

  const [toggleDropdown, setToggleDropdown] = useState(false)

  const handleClick = () => {
    setToggleDropdown(!toggleDropdown)
  }

  return (
    <div>
        <div className="h-16 bg-green mb-10 w-full overscroll-none touch-none">
            <div className='md:container md:mx-auto w-screen h-full flex items-center justify-between md:px-0 px-8'>
                <div className='w-48 h-full flex items-center'>
                    <Link to={ROUTES.HOME}>
                        <p className='text-white font-bold text-2xl'>Hyperbowl-2</p>
                    </Link>
                </div>
                <div className='hidden md:flex'>
                    <Link to={ROUTES.HOME}>
                        <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-8 text-white mx-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" 
                        viewBox="0 0 20 20" 
                        fill="currentColor">
                        <path 
                        d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                    </Link>
                    <Link to={ROUTES.CREATE}>
                        <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-8 text-white mx-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" 
                        viewBox="0 0 20 20" 
                        fill="currentColor">
                        <path 
                        fillRule="evenodd" 
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" 
                        clipRule="evenodd" />
                        </svg>
                    </Link>
                    <Link to={ROUTES.PEOPLE}>
                        <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-8 text-white mx-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor" 
                        strokeWidth={2}>
                        <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        </Link>
                    <button
                    type='button'
                    title='Sign Out'
                    onClick={ async () => {
                        await signOut(auth)
                        navigate(ROUTES.LOGIN)
                    }}>
                        <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-8 text-white mx-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor" 
                        strokeWidth={2}>
                        <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                    </button>
                </div>
                <div className='md:hidden'>
                    <button onClick={handleClick}>
                        {!toggleDropdown ?                         
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            strokeWidth={1.5} 
                            stroke="currentColor" 
                            className="w-7 text-white">
                            <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg> 
                        : 
                        <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        strokeWidth={1.5} 
                        stroke="currentColor" 
                        className="w-7 text-white">
                        <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M6 18L18 6M6 6l12 12" />
                      </svg>
                      }
                    </button>
                </div>
            </div>
            <div className={!toggleDropdown ? 'hidden' : 'md:hidden transition ease-in-out delay-150 flex z-100 flex-col p-2 items-start justify-center w-full border-b opacity-100 bg-white border-gray mb-2 absolute'}>
                <div className='flex w-full justify-center items-center'>
                    <Link to={ROUTES.HOME} className='flex w-full justify-center items-center py-2 hover:bg-light-gray rounded-lg p-2 m-1 hover:cursor-pointer'>
                        <div className='flex flex-row items-center'>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                className="h-8 text-green mx-2" 
                                viewBox="0 0 20 20" 
                                fill="currentColor">
                                <path 
                                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                            </svg>
                            <p className='text-green font-bold'>Home</p>
                        </div>
                    </Link>
                    <Link to={ROUTES.CREATE} className='flex w-full justify-center items-center py-2 hover:bg-light-gray rounded-lg p-2 m-1 hover:cursor-pointer'>
                        <div className='flex flex-row items-center'>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                className="h-8 text-green mx-2" 
                                viewBox="0 0 20 20" 
                                fill="currentColor">
                                <path 
                                fillRule="evenodd" 
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" 
                                clipRule="evenodd" />
                            </svg>
                            <p className='text-green font-bold'>Create</p>
                        </div>
                    </Link>
                </div>
                <div className='flex w-full justify-center items-center'>
                    <Link to={ROUTES.PEOPLE} className='flex w-full justify-center items-center py-2 hover:bg-light-gray rounded-lg p-2 m-1 hover:cursor-pointer'>
                        <div className='flex flex-row items-center gap-2 ml-2'>
                            <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-8 text-green" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor" 
                            strokeWidth={2}>
                            <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p className='text-green font-bold'>People</p>
                        </div>
                    </Link>
                    <button onClick={ async () => {
                        await signOut(auth)
                        navigate(ROUTES.LOGIN)
                    }} className='flex w-full justify-center items-center py-2 hover:bg-light-gray rounded-lg p-2 m-1 hover:cursor-pointer'>
                        <div className='flex flex-row items-center'>
                            <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-8 text-green mx-2" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor" 
                            strokeWidth={2}>
                            <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                            </svg>
                            <p className='text-green font-bold'>Logout</p>
                        </div>
                    </button>
                </div>
            </div>  
        </div>
    </div>
  )
}

export default Header