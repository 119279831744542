import React from 'react'; 
import { getStoriesWrittenByUser } from '../../services/firebase';
import { useState, useEffect } from 'react';
import Story from '../story/Story';

function ProfileBody({ username, userId, userMadeStory }) {
  const [stories, setStories] = useState(null)
  const [storyCounter, setStoryCounter] = useState(15)
  
  useEffect(() => {
    const getStoriesForProfile = async () => {
      const storiesForProfile = await getStoriesWrittenByUser(userId)
      setStories(storiesForProfile)
    }
  
    getStoriesForProfile()
  }, [userId])

  const addToStoryCounter = () => {
    setStoryCounter(storyCounter + 15)
  }

  return (
    <div>  
      { stories !== null ? 
      <div className='md:w-3/4 w-full mx-auto flex flex-col items-center'>
        { stories.slice(0, storyCounter).map((story) => {
          return (
            <Story 
            username={username}
            text={story.story}
            likes={story.likes.length}
            comments={story.comments}
            likedStory={story.userLikedStory}
            docId={story.docId}
            userId={story.userId}
            timeStamp={story.dateCreated}
            creatorMadeStory={userMadeStory} 
            />
          )
        })}
      </div> 
      : null}
      { stories !== null && stories.length !== 0 ? 
        null
        : 
        <div className='flex items-center justify-center'>
          <p className='text-gray-base text-lg font-bold'>User Has No Stories</p>
        </div>
      }

      { stories !== null ? stories.length >= 15 && storyCounter < stories.length && (
          <div className='w-full flex justify-center ml-18'>
            <button onClick={addToStoryCounter} className="shadow-md border-2 border-gray-base py-1.5 px-2 bg-green shadow-md rounded-full h-8 flex items-center justify-center ml-18 my-7">
                <p className='font-bold text-white'>See More Stories</p>
            </button>
        </div>
      ) : null}
    </div>
  )
}

export default ProfileBody