import React from 'react';
import Header from '../components/Header';
import { getUserSearchResults, getUserByUserId } from '../services/firebase';
import { useState, useContext, useEffect } from 'react';
import useUser from '../hooks/use-user';
import UserContext from '../contexts/Authcontext';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

function People() {
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState(null)
  const [userIsFollowing, setUserIsFollowing] = useState(null)

  const currentUser = useContext(UserContext)
  const { userInfo } = useUser(currentUser.uid)

  useEffect(() => {
    const handleFollowingList = async () => {
        const followingList = await Promise.all(userInfo.userData.following.map((followingUser) => {
            const result = getUserByUserId(followingUser)
            return result
        }))
    
        setUserIsFollowing(followingList)
        console.log(userIsFollowing)
    }
 
    if(userInfo !== undefined) {
        handleFollowingList()
    }
  }, [userInfo])

  const handleUserSearch = async () => {
    const formattedSearchTerm = searchValue.charAt(0).toUpperCase() + searchValue.slice(1)
    const searchResults = await getUserSearchResults(formattedSearchTerm)
    setSearchResult(searchResults)
  }

  const resetSerachValue = () => {
    setSearchResult(null)
    setSearchValue('')
    document.getElementById('search').value = ''
  }

  return (
    <div>
        <Header />
        <div className='w-full flex flex-col items-center justify-center touch-pan-auto'>
            <div className='md:w-4/5 w-full md:px-0 px-7'>
                <form className='flex justify-between items-center rounded-md mx-3 border border-gray shadow-lg'>
                    <input 
                        className='w-full h-12 outline-0 px-3 rounded-md flex bg-white items-center justify-center text-gray-base text-sm md:text-md'
                        placeholder='Search for users'
                        onChange={({target}) => setSearchValue(target.value)}
                        id="search">
                    </input>  
                    { searchResult === null ? 
                    <button 
                        className={`text-sm text-white mb-1 mr-2 px-1.5 py-1.5 bg-green rounded-full ${searchValue === '' ? "md:opacity-25" 
                        : null }`}
                        onClick={handleUserSearch} 
                        type="button"
                        disabled={searchValue.length < 1}>
                        Search
                    </button>
                    : 
                    <button onClick={resetSerachValue}>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        strokeWidth={1.5} 
                        stroke="currentColor" 
                        className="w-7 text-gray-base mr-3">
                        <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </button>
                    }
                </form>
            </div>
            { searchResult === null ? 
            <div className='md:w-4/5 w-full p-3 md:px-3 px-10'>
                <p className='text-lg text-green font-bold mb-4'>Following</p>
                { userIsFollowing !== null ? 
                <div className='grid md:grid-cols-3 lg:grid-cols-5 gap-4 w-full'>
                    { userIsFollowing.map((user) => {
                        return (
                            <Link to={`/p/${user.userData.username}`}>
                                <div className='h-12 w-full flex items-center border border border-gray justify-between rounded-md shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>
                                    <div className='flex items-center'>
                                        { user.userData.imageLink !== "" ? <img src={user.userData.imageLink} className="h-7 w-7 shadow-md rounded-md mx-3"></img> : <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        className="h-7 text-green border-2 border-green rounded-md mx-3" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="currentColor" 
                                        strokeWidth={2}>
                                        <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>}
                                        <p className='text-gray-base text-sm'>{user.userData.username}</p>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div> 
                : 
                <div className='w-full h-36'>
                <Skeleton height={175}></Skeleton>
                </div>
                }
            </div>
            : searchResult.map((result) => {
            return (
                <div className='h-12 w-1/3 mx-12 flex items-center justify-between rounded-md border border-gray shadow-md my-2'>
                    <div className='flex items-center'>
                        { result.imageLink !== "" ? <img src={result.imageLink} className="h-7 w-7 shadow-md rounded-md mx-3"></img> : <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-7 text-green border-2 border-green rounded-md mx-3" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor" 
                        strokeWidth={2}>
                        <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>}
                        <p>{result.username}</p>
                    </div>
                    <Link to={`/p/${result.username}`}>
                        <div>
                            <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24"
                            strokeWidth={1.5} 
                            stroke="currentColor" 
                            className="w-7 text-green mr-3">
                            <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg>
                        </div>
                    </Link>
                </div>
            )
        })}
            <div className='mt-14 w-full flex flex-col items-center'>
                <p className='text-gray-base text-sm font-bold'>Search for people by their usernames on Hyperbowl-2.</p>
                <div className='flex flex-row jsutify-between items-center w-4/6 px-3'>
                    <div className='h-[1px] w-1/2 bg-gray'></div>
                    <div className='flex mx-10'>
                    { searchResult !== null ? <p className='mr-2 font-bold text-gray-base text-lg'>{searchResult.length}</p> : <p className='text-gray-base mr-2 text-lg font-bold'>0</p>}
                    <p className='text-gray-base font-bold text-lg'>Results</p>
                    </div>
                    <div className='h-[1px] w-1/2 bg-gray'></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default People