import React from 'react'; 
import Header from '../components/Header';
import UserContext from '../contexts/Authcontext';
import { useContext, useState } from 'react';
import useUser from '../hooks/use-user';
import { makeNewStory } from '../services/firebase';
import Skeleton from 'react-loading-skeleton';

function Create() {
  const data = useContext(UserContext)
  const { userInfo } = useUser(data.uid)

  const [story, setStory] = useState('')
  const [toggleStory, setToggleStory] = useState(false)
  const [error, setError] = useState('')

  const handleNewStory = async (event) => {
    event.preventDefault()
    
    const newDate = Date.now()

    try {
      if(toggleStory === false) {
        await makeNewStory(userInfo.userData.userId, story, newDate)
        setToggleStory(true)
      } else {
        setError('You have already posted a story, refresh the page to post another.')
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Header />
      { userInfo !== undefined ?
        <div className='flex flex-col items-center justify-center touch-pan-auto'>
          <div className="md:w-4/5 w-5/6 h-72 rounded-md shadow-md border border-gray">
            <div className='w-full h-12 flex justify-start items-center border-b border-gray'>
              { userInfo.userData.imageLink !== '' ? <img className='ml-2 h-8 w-8 rounded-md' src={userInfo.userData.imageLink}></img> : 
              <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-8 ml-2 text-green border-2 border-green rounded-md" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor" 
              strokeWidth={2}>
              <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>}
              <p className='ml-2 text-sm'>{userInfo.userData.username}</p>
            </div>
            <div className='w-full px-2 flex-col'>
              <form onSubmit={handleNewStory} method="POST">
                <textarea
                className='w-full h-56 mt-2 overflow-hidden outline-0 text-gray-base text-sm'
                aria-label='Enter what you want to post'
                type="text" 
                placeholder='Type what you want to post... '
                value={story}
                onChange={({target}) => setStory(target.value)}/>
              </form>
              <div className='w-full flex justify-center mt-5'>
                <button disabled={story.length < 1} type='submit' onClick={handleNewStory} 
                className={`h-9 w-24 flex items-center justify-center bg-green rounded-full ${story === '' ? "opacity-25" : null}`}>
                  <p className='text-white font-bold text-sm'>Post</p>
                </button>
              </div>
            </div>
          </div>
          { toggleStory === true ? 
            <div className='w-2/3 flex mt-24 justify-center text-center items-center border-2 border-green rounded-md p-2'>
              <p className='text-green font-bold text-md'>You have sucessfully posted on Hyperbowl-2!</p>
            </div> 
          : null }

          { error !== '' ? 
            <div className='w-2/3 h-8 flex mt-5 justify-center text-center items-center border-2 border-green rounded-md'>
            <p className='text-green font-bold text-md'>{error}</p>
          </div> 
          : null}
        </div>
        : 
        <div className='flex flex-col items-center'>
          <Skeleton width={800} height={300} className="mb-4"></Skeleton>
          <Skeleton width={100} height={40}></Skeleton>
        </div>
      }
    </div>
  )
}

export default Create