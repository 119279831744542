import React from 'react'; 
import { loginWithEmailAndPassword } from '../services/firebase'; 
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as ROUTES from '../constants/routes'; 

function Login() {

  const navigate = useNavigate()

  const [email, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  
  const signInHandler = async (event) => {
    event.preventDefault()

    try {
      await loginWithEmailAndPassword(email, password)
      navigate(ROUTES.HOME)
    } catch (err) {
      console.log(err)
      setError(err.message)
    }
  }

  useEffect(() => {
    document.title = 'Hyperbole-2 - Log In'
  })

  return (
    <div className='container mx-auto flex flex-col items-center justify-center h-screen touch-pan-auto'>
      <div className='flex flex-col justify-center items-center container px-10 md:h-3/5 h-4/5 md:w-1/3 w-3/4 rounded-lg bg-white shadow-2xl border border-gray'>
        <div className='flex-col sm:flex-row mb-10'>
            <p className='md:text-4xl text-3xl font-bold text-green'>Hyperbowl-2</p>
        </div>
        {error && <p className='mb-4 text-xs text-red'>{error}</p>}
        <form onSubmit={signInHandler} method="POST">
          <input
            aria-label="Enter your email address"
            type="text"
            placeholder="Email address"
            className="outline-0 text-sm text-gray-base w-full py-5 px-4 h-2 border border-gray rounded mb-2"
            onChange={({target}) => setEmailAddress(target.value)}
            value={email}
            />
          <input
            aria-label="Enter your Password"
            type="password"
            placeholder="Password"
            className="outline-0 text-sm text-gray-base w-full py-5 px-4 h-2 border border-gray rounded mb-2"
            onChange={({target}) => setPassword(target.value)}
            value={password}
          />            
            <div className='w-full h-12 flex justify-center mt-3'>
              <button 
              className='py-1 px-1.5 w-36 h-9 border-2 border-green bg-white outline-0
              rounded-full hover:cursor-pointer flex justify-center items-center mt-5'
              type='submit'>
                <p className={`text-green font-bold text-sm`}>Login</p>
              </button>
            </div>
        </form>
      </div>
      <div className='flex mt-5'>
        <p className='font-bold text-green text-sm text-center mr-1'>Don't have an account?</p>
        <Link to={ROUTES.SIGN_UP} className='hover:underline text-green font-bold text-center text-sm'>Sign Up</Link>
      </div>
    </div>
  )
}

export default Login