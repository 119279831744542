import React, { useEffect, useContext } from 'react'; 
import UserContext from '../../contexts/Authcontext';
import { useState } from 'react';
import { getStoriesWrittenByUser, updateUserFollowed, updateUserFollowing, updateUserRemovedFollowed, updateUserDeletedFollowing, makeNewAdmin} from '../../services/firebase';
import { storage } from '../../lib/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addImageLink } from '../../services/firebase';
import useUser from '../../hooks/use-user';

function ProfileInfo({userId, followersLength, followingLength, username, isUserProfile, docId, currentImageLink, type}) {

    const [ storiesByUser, setStoriesByUser ] = useState([])
    const [ profileImage, setProfileImage ] = useState(null)
    const [ image, setImage ] = useState(null)
    const [ isFollowingUser, setIsFollowingUser ] = useState(null)
    const [ toggleDropdown, setToggleDropdown ] = useState(false)
    const [ toggleAdmin, setToggleAdmin ] = useState(false)
    const [ toggleFinsihedUpload, setToggleFinishedUpload] = useState(false)

    const visitingUser = useContext(UserContext)
    const { userInfo } = useUser(visitingUser.uid)
    console.log(userInfo)

    useEffect(() => {
        const getStoryLength = async () => {
            const stories = await getStoriesWrittenByUser(userId)
            setStoriesByUser(stories)
        }

        checkIfUserIsFollowing()
        getStoryLength()

    }, [userId, userInfo])

    const uploadImage = async () => {
        if(profileImage === null) {
            return null
        } else {
            const imageRef = ref(storage, `${userId}/`)
            uploadBytes(imageRef, profileImage).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setImage(url)
                    addImageLink(url, docId)
                    setToggleFinishedUpload(true)
                })
            })
        }
    }

    const handleMakingNewAdmin = async function () {
        if(userInfo.userData.type === 'admin') {
            await makeNewAdmin(docId, false)
            setToggleAdmin(true)
        } else {
            return null
        }
    }

    const handleUserFollowing = async () => {
        if(userInfo !== null) { 
            setIsFollowingUser(true)
            updateUserFollowing(userInfo.id, userId, false)
            updateUserFollowed(docId, userInfo.userData.userId, false)
        } else {
            return null 
        }
    }

    const handleRemovedUserFollowing = async () => {
        if(userInfo !== null) {
            setIsFollowingUser(false)
            updateUserDeletedFollowing(userInfo.id, userId, true)
            updateUserRemovedFollowed(docId, userInfo.userData.userId, true)
        } else {
            return null
        }
    }

    const checkIfUserIsFollowing = () => {
        if(userInfo !== undefined) {
            if(userInfo.userData.following.includes(userId)) {
               setIsFollowingUser(true)
               console.log('hello')
            } else {
                setIsFollowingUser(false)
            }
        } 
    } 

    return (
        <div className='w-full container mx-auto justify-center items-center flex h-60 mb-5'>
            <div className='md:w-5/6 w-full flex justify-center items-center'>
                {
                    isUserProfile === true && toggleDropdown === true ?
                    <div className='flex-col'>
                        <div className='flex'>
                            <input type="file" className='w-1/2' onChange={(event) => {
                                setProfileImage(event.target.files[0])
                            }}>
                            </input>
                            { profileImage !== null ? 
                            <button onClick={uploadImage} className="bg-green rounded-full shadow-md ml-3 ">
                            <p className='font-bold text-white text-sm px-2 py-1'>Add Image</p> 
                            </button> : null}
                        </div>
                        { toggleFinsihedUpload === true ? 
                        <div className='w-1/2 p-1 mt-3 border border-green rounded-md '>
                            <p className='text-green text-xs'>You have sucessfully uploaded your profile picture. Reload the page to see your profile picture.</p>
                        </div>
                        : null}
                    </div>

                    : currentImageLink !== "" ? <img className='md:w-36 md:h-36 w-28 h-28 rounded-full border border-gray shadow-lg mr-3' src={currentImageLink}></img> 
                    : image !== null ? <img className='w-36 h-36 rounded-full border border-gray shadow-lg mr-3' src={image}></img> 
                    : <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-36 p-3 text-green border-4 border-green rounded-full mr-3" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor" 
                    strokeWidth={2}>
                    <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                }
                <div className='flex flex-col'>
                    <div className="flex">
                        <p className='font-bold mt-1 text-gray-base text-xl mr-2'>{username}</p>
                        {
                            isUserProfile === false ? null : <p className='font-bold text-xl mt-1 text-green'>Me</p>
                        }
                    </div>
                    <div>
                        <div className='mt-1 flex md:flex-row flex-col'>
                                <div className='flex-col'>
                                {
                                    storiesByUser.length === 1 ? 
                                    <p className='text-gray-base text-sm flex mr-4'><p className='font-bold mr-1'>{storiesByUser.length}</p> post</p>
                                    : <p className='text-gray-base text-sm flex mr-4'><p className='font-bold mr-1'>{storiesByUser.length}</p> posts</p>
                                }
                                {
                                    followersLength === 1 ? 
                                    <p className='text-gray-base text-sm flex mr-4'><p className='flex'><p className=' mr-1 font-bold'>{followersLength}</p>follower</p></p>
                                    : <p className='text-gray-base text-sm flex mr-4'><p className='mr-1 font-bold'>{followersLength}</p> followers</p>
                                }
                                <p className='text-gray-base text-sm flex'><p className='font-bold mr-1'>{followingLength}</p>following</p>
                            </div>
                        </div>
                    </div>
                    { isUserProfile === true ? 
                    <button onClick={() => {
                        setToggleDropdown(!toggleDropdown)
                    }} className="px-1 py-1 border-2 border-green rounded-full mt-3">
                        <p className='text-green font-bold text-xs'>Set Profile Picture</p>
                    </button> : null}
                    <div className='flex md:flex-row'>
                        { isUserProfile === false ? 
                            isFollowingUser !== null || undefined ? 
                                isFollowingUser === false ?
                                <button onClick={handleUserFollowing} className="w-full py-1.5 bg-green rounded-full shadow-md mt-1 mr-1">
                                    <p className='font-bold text-white text-sm'>Follow</p>
                                </button> 
                                : 
                                <div className='flex'>
                                    <p className='text-gray-base font-bold text-sm italic mt-2.5 mr-2'>Following</p>
                                    <button className='py-1.5 px-2 rounded-full shadow-md mt-1 border border-gray' onClick={handleRemovedUserFollowing}>
                                        <p className='font-bold text-green text-sm'>Unfollow</p>
                                    </button>
                                </div>
                            : null
                        : null }
                        { userInfo !== undefined && isUserProfile === false && userInfo.userData.type === "admin" ?
                            toggleAdmin === false && type !== 'admin' ?
                                <div className='flex justify-start'>
                                    <button className='font-bold text-green text-sm md:ml-2 ml-0' onClick={handleMakingNewAdmin}>Make Admin</button>
                                </div>
                            : <p className='text-sm text-gray md:mt-3 mt-0 md:ml-2 ml-0'>Already Admin</p>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileInfo