import React from 'react'; 
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../constants/routes'; 

function NoUser() {
  const navigate = useNavigate()

  return (
    <div className='w-full h-full flex items-center justify-center tou-pan-y'>
        <div className='flex justify-center items-center'>
            <p>Image Goes Here</p>
        </div>
        <div className='flex justify-between items-center w-1/3'>
            <button className='w-16 h-8 rounded-full flex justify-center items-center border-2 border-green' 
            onClick={navigate(ROUTES.SIGN_UP)}>
                <p className='text-md font-bold text-green'>Sign Up</p>
            </button>

            <button className='w-16 h-8 rounded-full flex justify-center items-center border-2 border-green'
            onClick={navigate(ROUTES.LOGIN)}>
                <p className='text-md font-bold text-green'>Login</p>
            </button>
        </div>
    </div>
  )
}

export default NoUser