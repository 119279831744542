import { useState, useEffect } from "react";
import { getUserByUserId } from "../services/firebase";

export default function useUser(userId) { 
    const [userInfo, setUserInfo] = useState()

    useEffect(() => {
        async function getUserObj(userId) {
            const user = await getUserByUserId(userId)
            setUserInfo(user || { userInfo: [] })
        }

        if(userId) {
            getUserObj(userId)
        }
    }, [userId])

    return { userInfo }
}