import React from 'react'; 
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import * as ROUTES from '../constants/routes'; 
import { doesUsernameExsist, authenticateUserWithEmailAndPassword, createNewUser } from '../services/firebase'; 
import { getAuth, updateProfile } from 'firebase/auth'; 

function Signup() {
  const auth = getAuth()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Sign Up - Hyperbole-2'
  })

  const [ username, setUsername ] = useState(''); 
  const [ emailAddress, setEmailAddress ] = useState(''); 
  const [ password, setPassword ] = useState(''); 
  const [ fullName, setFullName ] = useState('')
  const [ error, setError ] = useState(); 

  const isInvalid = username === '' && password === ''; 

  const handleSignUp = async (event) => {
    event.preventDefault()
    const alterdUsername = username.charAt(0).toUpperCase() + username.slice(1)
    const usernameExsists = await doesUsernameExsist(alterdUsername);

    if(usernameExsists === 0) {
      try {
        const userResult = await authenticateUserWithEmailAndPassword(emailAddress, password)
        await createNewUser(emailAddress, alterdUsername, userResult.user.uid, fullName, Date.now())
        await updateProfile(auth.currentUser, {
          displayName: alterdUsername
        })

        navigate(ROUTES.HOME)
      } catch(error) {
        setUsername('')
        setPassword('')
        setEmailAddress('')
        setError(error.message)
        console.log(error)
      }
    } else if (usernameExsists > 0) {
      setUsername('')
      setError('That Username is already taken. Time to get creative!')
    }
  }

  return (
    <div className='container mx-auto flex flex-col items-center justify-center h-screen touch-pan-auto'>
      <div className='flex flex-col justify-center items-center container px-10 h-3/5 md:w-1/3 w-5/6 rounded-lg bg-white shadow-2xl border border-gray'>
        <div className='flex mb-7 items-center'>
          <p className='md:text-4xl text-3xl font-bold text-green'>Hyperbowl-2</p>
        </div>
        {error && <p className='mb-4 text-xs text-red'>{error}</p>}
        <form onSubmit={handleSignUp} method="POST">
          <input
            aria-label="Enter your email address"
            type="text"
            placeholder="Email address"
            className="outline-0 text-sm text-gray-base w-full py-5 px-4 h-2 border border-gray rounded mb-2"
            onChange={({target}) => setEmailAddress(target.value)}
            value={emailAddress}
            />
          <input
            aria-label="Enter your Username"
            type="text"
            placeholder="Username"
            className="outline-0 text-sm text-gray-base w-full py-5 px-4 h-2 border border-gray rounded mb-2"
            onChange={({target}) => setUsername(target.value)}
            value={username}
          />    
          <input
            aria-label="Enter your full name"
            type="text"
            placeholder='Full Name'
            className='outline-0 text-sm text-gray-base w-full py-5 px-4 h-2 border border-gray rounded mb-2'
            onChange={({target}) => setFullName(target.value)}
          />   
          <input
            aria-label="Enter your password"
            type="password"
            placeholder="Password"
            className="outline-0 text-sm text-gray-base w-full py-5 px-4 h-2 border border-gray rounded mb-2"
            onChange={({target}) => setPassword(target.value)}
            value={password}
          />
            <div className='w-full h-12 flex justify-center mt-3'>
              <button 
              disabled={isInvalid}
              className='py-1 px-1.5 w-36 h-9 border-2 border-green bg-white outline-0 mt-2
              rounded-full hover:cursor-pointer flex justify-center items-center'
              type='submit'>
                <p className={`text-green font-bold text-sm`}>Sign Up</p>
              </button>
            </div>
        </form>
      </div>
      <div className='flex mt-5'>
        <p className='font-bold text-green text-sm text-center mr-1'>Already have an account?</p>
        <Link to={ROUTES.LOGIN} className='hover:underline text-green font-bold text-center text-sm'>Login</Link>
      </div>
    </div>
  )
}

export default Signup