import React from 'react'; 
import UserContext from '../contexts/Authcontext';
import { useContext, useState, useEffect } from 'react';
import useUser from '../hooks/use-user';
import Skeleton from 'react-loading-skeleton';
import { getSuggestedProfiles } from '../services/firebase';
import SuggestedProfile from './SuggestedProfile';
import { Link } from 'react-router-dom';

function Sidebar() {
  const data = useContext(UserContext)
  const { userInfo } = useUser(data.uid)

  const [profileInfo, setProfileInfo] = useState()
  const [profileCount, setProfileCount] = useState(15)


  
  useEffect(() => {
    async function getProfiles() {
        const profileData = await getSuggestedProfiles(data.uid, userInfo.userData.following)
        setProfileInfo(profileData)
        console.log(profileData)
    }
    
    if(userInfo !== undefined) {
       getProfiles()
    }

  }, [userInfo, data.uid])

  const addToProfileCount = () => {
    setProfileCount(profileCount + 15)
  }

  console.log(profileInfo)

  return (
    <div className='md:w-1/3 w-full flex md:justify-start justify-center'> 
        { userInfo ?
        <div className='mt-2 md:w-60 w-5/6 overflow-x-auto md:h-screen md:flex-col flex justify-start items-start'>
            <Link to={`/p/${userInfo.userData.username}`} className="md:m-0 mr-2">
                <div className='flex md:flex-row flex-col items-center justify-start md:w-48 w-16 mb-8 md:p-0 p-1.5 md:rounded-none md:shadow-none shadow-lg rounded-lg md:border-0 border border-gray-base'>
                    { userInfo.userData.imageLink !== "" ? <img src={userInfo.userData.imageLink} className='md:h-14 h-14 w-36 md:shadow-none shadow-xl md:w-14 rounded-full md:mr-3 m-0' alt="Profile"></img>
                    : 
                    <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="w-14 h-14 p-1 text-green border-4 border-green rounded-full m-0 md:mr-2" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor" 
                    strokeWidth={2}>
                    <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    }
                    <div className='flex-col md:block hidden'>
                        <p className='font-bold'>{userInfo.userData.username}</p>
                        <p>{userInfo.userData.fullName}</p>
                    </div>
                    <p className='font-bold text-green text-md block md:hidden'>You</p>
                </div>
            </Link>
            <p className='font-bold text-gray-base ml-1 mb-5 md:block hidden'>Suggestions For You</p>
            { !profileInfo || profileInfo === undefined ? null
            : profileInfo.slice(0, profileCount).map((profile) => {
            return (
                <SuggestedProfile 
                username={profile.username} 
                profileId={profile.userId} 
                loggedInUserDocId={userInfo.id} 
                profileDocId={profile.docId}
                currentUserId={data.uid}/>
            )
            })}

            { profileInfo !== undefined ? profileInfo.length !== 0 ? null :
            <div className='w-full md:mt-0 mt-5'>
                <div className='flex md:flex-col flex-row w-1/2 items-center justify-center md:ml-4 ml-0 mb-1'>
                    <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-6 md:ml-0 ml-4 text-green" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor" 
                    strokeWidth={2}>
                    <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <p className='ml-1 text-green text-xs'>
                    No new people to follow
                </p>
            </div> : null }

            { profileInfo === undefined ? null : profileInfo.length >= 15 && profileCount < profileInfo.length && (
                <div className='w-full flex justify-center'>
                    <button onClick={addToProfileCount} className="shadow-md border border-gray-base w-48 bg-green rounded-full h-8 flex items-center justify-center ml-18 ">
                        <p className='font-bold text-white'>See More Stories</p>
                    </button>
                </div>
            )}
        </div>
        : 
        <div className='w-36'>
            <Skeleton height={20}/>
            <Skeleton count={1} height={60} width={200} />
            <div className='mt-10'>
                <Skeleton count={3} height={40} width={220} className="my-2"/>
            </div>
        </div>
        }
    </div>
  )
}

export default Sidebar