import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; 
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; 

const firebaseConfig = {
  apiKey: "AIzaSyAjj-wi-AA5rCJcMibYMyTotYBfo7RVb6A",
  authDomain: "treehouse-4c466.firebaseapp.com",
  projectId: "treehouse-4c466",
  storageBucket: "treehouse-4c466.appspot.com",
  messagingSenderId: "591425947099",
  appId: "1:591425947099:web:21cf7e4462e8b37bb713fe",
  measurementId: "G-2Y9XXB2VGZ"
};

// Firebase Initialization + Database Configuration and Variable 
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)
