import { db } from "../lib/firebase";
import { collection, getDocs, query, where, addDoc, getDoc, doc, limit, updateDoc, arrayUnion, arrayRemove, deleteDoc, orderBy} from "firebase/firestore";
import { getAuth, updateProfile, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut} from 'firebase/auth'; 

const userCollectionRef = collection(db, "users")
const auth = getAuth()

export async function doesUsernameExsist(username) {
    const q = query(collection(db, "users"), where("username", "==", username));
    const querySnapshot = await getDocs(q);
    const result = querySnapshot.docs.length

    return result 
}

export async function getUserByUsername(username) {
    const q = query(collection(db, "users"), where("username", "==", username))
    const querySnapshot = await getDocs(q)
    const result = querySnapshot.docs.map((doc) => ({
        ...doc.data(), 
        id: doc.id
    }))
    return result
}

export async function authenticateUserWithEmailAndPassword(email, password) {
    return await createUserWithEmailAndPassword(auth, email, password)
}

export async function updateAccountWithDisplayName(username) {
    return await updateProfile(auth.currentUser, {
        displayName: username
    })
}

export async function getUserByUserId(userId) {
    let data = {}
    const q = query(collection(db, "users"), where("userId", "==", userId))
    const querySnapshot = await getDocs(q)
    querySnapshot.docs.forEach((doc) => {
        data = {
            userData: doc.data(), 
            id: doc.id
        }
    })
    return data
}

export async function loginWithEmailAndPassword(email, password) {
    return await signInWithEmailAndPassword(auth, email, password)
}

export async function createNewUser(email, username, userId, fullName, currentDate) {; 
    await addDoc(userCollectionRef, {
        username: username, 
        email: email, 
        userId: userId, 
        createdAt: currentDate, 
        following: [], 
        followers: [], 
        fullName: fullName, 
        imageLink: '', 
        type: 'user'
    })
}

export async function addImageLink(imageLink, docId) {
    const userRef = doc(db, "users", docId)

    await updateDoc(userRef, {
        imageLink: imageLink
    })
}

export async function getSuggestedProfiles(userId, following) {
    const q = query(collection(db, "users"), limit(15));
    const querySnapshot = await getDocs(q)
    const data = querySnapshot.docs.map((user) => ({
        ...user.data(), 
        docId: user.id
    })).filter((profile) => 
        profile.userId !== userId && !following.includes(profile.userId)
    )

    return data;
}

export async function updateUserFollowing(loggedInUserDocId, followingId, isFollowingProfile) {
    const userRef = doc(db, "users", loggedInUserDocId)

    if( isFollowingProfile === true ) {
        return null 
    } else {
        await updateDoc(userRef, {
            following: arrayUnion(followingId)
        })
    }
}

export async function updateUserDeletedFollowing(loggedInUserDocId, followingId, isFollowingProfile) {
    const userRef = doc(db, "users", loggedInUserDocId)

    if(isFollowingProfile === true) {
        await updateDoc(userRef, {
            following: arrayRemove(followingId)
        })
    } else {
       return null
    }
}

export async function checkIfUserIsAdmin(userId) {
    const currentUser = await getUserByUserId(userId)
    const ifAdmin = currentUser.userData.type

    if(ifAdmin === 'admin') {
        return true 
    } else {
        return false
    }
}

export async function isUserFollowing(userFollowingArray, userId) {
    let userIsFollowing = false

    if(userFollowingArray.includes(userId)) {
        userIsFollowing = true 
    }

    return userIsFollowing
}

export async function updateUserFollowed(profileDocId, userId, isFollowingProfile) {
    const userRef = doc(db, "users", profileDocId)

    if(isFollowingProfile === true) {
        return null
    } else {
        await updateDoc(userRef, {
            followers: arrayUnion(userId)
        })
    }
}

export async function updateUserRemovedFollowed(profileDocId, userId, isFollowingProfile) {
    const userRef = doc(db, "users", profileDocId)

    if( isFollowingProfile === true) {
        await updateDoc(userRef, {
            followers: arrayRemove(userId)
        })
    } else {
        return null
    }
}

export async function getStories(following, userId) {
    let storiesWithUserDetails = null 

    const followingLength = following.length 

    if(followingLength < 11) {
        const simpleQuery = query(collection(db, 'stories'), where('userId', 'in', following), orderBy('dateCreated'))
        const querySnapshot = await getDocs(simpleQuery)
        const simpleQueryResult = querySnapshot.docs.map((story) => ({
            ...story.data(), 
            docId: story.id
        }))

        storiesWithUserDetails = await Promise.all(
            simpleQueryResult.map(async (story) => {
                let userLikedStory = false
                if (story.likes.includes(userId)) {
                    userLikedStory = true 
                }
    
                const user = await getUserByUserId(story.userId)
                const username = user.userData.username
    
                return {username, ...story, userLikedStory }
            })
        )
        
        return storiesWithUserDetails

    } else {
        const retrieveLimit = 10 
        const splitFollowingArray = following.slice(0, retrieveLimit)
        const secondSlpitFollowingArray = following.slice(retrieveLimit, following.length )

        const q = query(collection(db, "stories"), where("userId", "in", splitFollowingArray))
        const querySnapshot = await getDocs(q)
        const followedStories  = querySnapshot.docs.map((story) => ({
            ...story.data(), 
            docId: story.id
        }))
    
        const q2 = query(collection(db, "stories"), where("userId", "in", secondSlpitFollowingArray))
        const querySnapshot2 = await getDocs(q2)
        const followedStories2  = querySnapshot2.docs.map((story) => ({
            ...story.data(), 
            docId: story.id
        }))
    
        const combinedStories = [ ...followedStories, ...followedStories2 ]

        combinedStories.sort((a, b) => b.dateCreated - a.dateCreated)
    
        storiesWithUserDetails = await Promise.all(
            combinedStories.map(async (story) => {
                let userLikedStory = false
                if (story.likes.includes(userId)) {
                    userLikedStory = true 
                }
    
                const user = await getUserByUserId(story.userId)
                const username = user.userData.username
    
                return {username, ...story, userLikedStory }
            })
        )

        console.log(storiesWithUserDetails)
        return storiesWithUserDetails
    }

}

export async function getStoriesWrittenByUser(userId) {
    const q = query(collection(db, "stories"), where("userId", "==", userId), limit(15))
    const querySnapshot = await getDocs(q)
    const storiesFromUser = querySnapshot.docs.map((story) => ({
        ...story.data(), 
        docId: story.id
    }))

    const storiesWithUserDetails = await Promise.all(
        storiesFromUser.map(async (story) => {
            let userLikedStory = false
            if(story.likes.includes(userId)) {
                userLikedStory = true 
            }

            const user = await getUserByUserId(story.userId)
            const username = user.userData.username

            return {username, ...story, userLikedStory}
        })
    )

    return storiesWithUserDetails
}

export async function handleUserLikedStory(docId, userId, likedStory) {
    const storyRef = doc(db, "stories", docId)

    if(likedStory === true) {
        await updateDoc(storyRef, {
            likes: arrayRemove(userId)
        })
    } else {
        await updateDoc(storyRef, {
            likes: arrayUnion(userId)
        })
    }
}

export async function addComment(docId, commentInput, displayName) {
    const storyRef = doc(db, "stories", docId)

    await updateDoc(storyRef, {
        comments: arrayUnion({ 
            displayName: displayName, 
            text: commentInput
        })
    })
}

export async function makeNewStory(userId, story, dateCreated) {
    const storyRef = collection(db, 'stories')

    await addDoc(storyRef, {
        dateCreated: dateCreated, 
        story: story, 
        likes: [], 
        comments: [], 
        userId: userId
    })
}

export async function deleteStory(storyDocId) {
    const storyRef = doc(db, "stories", storyDocId)

    await deleteDoc(storyRef)
}

export async function deleteAccount(accountId) {
    const accountRef = doc(db, "users", accountId)

    await deleteDoc(accountRef)
}

export async function getUserSearchResults(searchValue) {
    const q = query(collection(db, "users"), where("username", "==", searchValue))
    const querySnapshot = await getDocs(q)
    const result = querySnapshot.docs.map((doc) => ({
        ...doc.data(), 
        id: doc.id
    }))
    return result
}

export async function makeNewAdmin(userDocId, isUserAdmin) {
    const userRef = doc(db, "users", userDocId)

    if(isUserAdmin === false) {
        await updateDoc(userRef, {
            type: 'admin'
        })
    } else {
        return null
    }
}

