import './styles/App.css';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import * as ROUTES from './constants/routes'; 
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './lib/firebase';
import { useState } from 'react';
import Home from './pages/Home'; 
import Login from './pages/Login'; 
import Create from './pages/Create'; 
import Signup from './pages/Signup';
import Profile from './pages/Profile'; 
import NoUser from './pages/No-User';
import UserContext from './contexts/Authcontext';
import People from './pages/People';

function App() {

  const [userData, setUserData ] = useState({})

  onAuthStateChanged(auth, (currentUser) => {
    setUserData(currentUser)
  })

  return (
      <Router>
        <UserContext.Provider value={userData}> 
          <Suspense fallback={<p>Loading</p>}>
            <Routes>
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.CREATE} element={<Create />} />
              <Route path={ROUTES.SIGN_UP} element={<Signup />} />
              <Route path={ROUTES.PROFILE} element={<Profile />} />
              <Route path={ROUTES.NOT_FOUND} element={<NoUser />} />
              <Route path={ROUTES.PEOPLE} element={<People />} />
            </Routes> 
          </Suspense>
        </UserContext.Provider>
      </Router>
  );
}

export default App;
