import React from 'react'; 
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Timeline from '../components/Timeline';
import { useEffect } from 'react';
import UserContext from '../contexts/Authcontext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../src/constants/routes'; 

function Home() {

  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Hyperbole-2'
  })

  const userData = useContext(UserContext)

  if(userData === null) {
    navigate(ROUTES.LOGIN)
  }

  return (
    <div className='overflow-x-hidden overscroll-auto touch-pan-auto'>
      <Header />
      <div className="w-full h-full flex md:flex-row flex-col justify-between container mx-auto">
        <Sidebar />
        <Timeline />
      </div>
    </div>
  )
}

export default Home